import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  background: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%",
  },
  container: {
    position: "absolute",
    top: 90,
    left: 320,
    height: "75%",
    width: "45%",
    backgroundColor: "white",
    alignItems: "center",
  },
  coverHeader: {
    padding: 5,
    alignItems: "center",
    borderBottomWidth: 1,
    width: "100%",
  },
  logoCover: {
    width: 150,
    height: 150,
  },
  title: {
    fontSize: 30,
    fontWeight: "lighter",
  },
  smallText: {
    fontSize: 10,
    margin: 2,
  },
  smallTextBold: {
    fontWeight: "bold",
    fontSize: 10,
  },
  mediumTextBold: {
    marginLeft: 2,
    marginTop: 2,
    marginBottom: 2,
    fontWeight: "bold",
    fontSize: 15,
  },
  mediumText: {
    marginLeft: 2,
    marginTop: 2,
    marginBottom: 2,
    fontSize: 15,
  },
  usernameInfo: {
    margin: "auto",
  },
  usernameCover: {
    color: "#9E1221",
    fontSize: 23,
    fontWeight: "bold",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
    backgroundColor: "#F3F4F5",
    width: "100%",
    height: "15%",
    padding: 10,
    margin: 25,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 6,
    paddingTop: 6,
    marginBottom: 5,
    borderBottomWidth: 2,
    borderBottomColor: "black",
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
  },
  username: {
    color: "#9E1221",
    fontWeight: "bold",
  },
  headerTitle: {
    fontSize: 30,
    fontWeight: "bold",
  },
  logo: {
    width: 70,
    height: 70,
  },
  content: {
    padding: 15,
  },
  topic: {
    fontSize: 26,
    fontWeight: "bold",
    marginTop: 3,
    marginBottom: 3,
  },
  subtopic: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 3,
    marginBottom: 3,
  },
  line: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
  },
  siteItem: {
    padding: 10,
    borderLeftWidth: 3,
    borderColor: "#D62E2E",
    marginTop: 5,
    marginBottom: 5,
  },
  appName: {
    fontSize: 18,
    fontWeight: "bold",
    paddingRight: 10,
  },
  id: {
    fontSize: 13,
    color: "#D9D9D9",
  },
  result: {
    fontSize: 13,
    fontWeight: "bold",
    marginLeft: 5,
    backgroundColor: "#58F43E",
    padding: 3,
    borderRadius: 5,
  },
  link: {
    marginLeft: 5,
    color: "#9E1221",
    textDecoration: "underline",
  },
  httpStatus: {
    fontSize: 13,
    fontWeight: "bold",
    marginLeft: 5,
    backgroundColor: "#F0F0F0",
    padding: 3,
    borderRadius: 5,
  },
  metadataDetails: {
    display: "flex",
    flexDirection: "row",
    margin: 2,
    paddingLeft: 10,
    paddingRight: 10,
    borderLeftWidth: 2,
    borderColor: "#000000",
  },
  avatar: {
    borderRadius: 2,
    margin: 3,
    width: 100,
    height: 100,
  },
  metadataText: {
    fontSize: 10,
  },
  rightPanel: {
    marginLeft: 20,
  },
});
